import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    importCompanies(param) {
        let url = `${this.baseUrl}/importCompanies`;
        return axios.post(url, param);
    },

    importStorageLocations(param) {
        let url = `${this.baseUrl}/importStorageLocations`;
        return axios.post(url, param);
    },

    importUsers(param) {
        let url = `${this.baseUrl}/importUsers`;
        return axios.post(url, param);
    },

    importTransportations(param) {
        let url = `${this.baseUrl}/importTransportations`;
        return axios.post(url, param);
    },

    importAssetTypes(param) {
        let url = `${this.baseUrl}/importAssetTypes`;
        return axios.post(url, param);
    },

    importDispatches(param) {
        let url = `${this.baseUrl}/importDispatches`;
        return axios.post(url, param);
    }
}
